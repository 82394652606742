<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" style="width: 100%;">
    <el-card class="box-card" style="text-align: left;">
      <div slot="header" class="clearfix">
        <span>交易流水管理</span>
      </div>

      <div>

        <br/>

        <el-form :inline="true" label-width="100px">

          <el-form-item label="订单号" class="form-item" prop="username">
            <el-input v-model="orderId" placeholder="请填写订单号" size="small"></el-input>
          </el-form-item>

          <el-form-item label="商户"  v-if="level==='1'">
            <el-select v-model="merchantId"  clearable filterable >
              <el-option v-for="item in merchant_list" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="起止日期">
            <el-date-picker
                v-model="date"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="defaultTime"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="showDate"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item style="padding-left:20px;">
            <el-button type="primary" size="small" style="margin-right:10px;" @click="getList">查询</el-button>
            <el-button type="warning" size="mini" @click="exportExcel" style="margin-left:10px;" :disabled="disabled">导出</el-button>
          </el-form-item>
        </el-form>

        <el-table :data="list"  stripe style="margin-top:10px;">
          <el-table-column type="index" label="序号" width="50" header-align="center" align="center"/>
          <el-table-column prop="orderNo" label="订单号" header-align="center" align="center"/>
          <el-table-column prop="merName" label="商铺名" header-align="center" align="center"/>
          <el-table-column prop="txnAmt" label="实收金额" header-align="center" align="center" :formatter="rounding"/>
          <el-table-column prop="date" label="支付时间" header-align="center" align="center"/>
          <el-table-column label="操作"  header-align="center" align="center">
            <template slot-scope="scope">
              <el-row>
                <el-button type="warning" size="mini" @click="scanDetail(scope.row.orderNo)" style="margin-left:10px;">查看详情</el-button>
              </el-row>
            </template>
          </el-table-column>
        </el-table>

        <el-dialog :visible.sync="isVisible" :before-close="clearRateParams">
          <el-descriptions class="margin-top" title="交易详情"  :column="1" border>
            <el-descriptions-item label="订单号" >{{orderItem.orderNo}}</el-descriptions-item>
            <el-descriptions-item label="商户名">{{orderItem.merName}}</el-descriptions-item>
            <el-descriptions-item label="交易时间">{{ orderItem.createdAt}}</el-descriptions-item>
            <el-descriptions-item label="交易状态">{{getStatus(orderItem.status)}}</el-descriptions-item>
            <el-descriptions-item label="付款方式" >{{getPayType(orderItem.payType)}}</el-descriptions-item>
            <el-descriptions-item label="第三方订单号" v-if="orderItem.thirdOrderNo">{{orderItem.thirdOrderNo}}</el-descriptions-item>
            <el-descriptions-item label="需付金额（元）" v-if="orderItem.txnAmtDiscount">{{getFee(orderItem.txnAmt)}}</el-descriptions-item>
            <el-descriptions-item label="优惠金额（元）" v-if="orderItem.txnAmtDiscount">{{getFee(orderItem.txnAmtDiscount)}}</el-descriptions-item>
            <el-descriptions-item label="交易金额（元）">{{ getFee(orderItem.txnAmtPay) }}</el-descriptions-item>
            <el-descriptions-item label="手续费(元)" >{{getFee(orderItem.rateFee)}}</el-descriptions-item>
            <el-descriptions-item label="总抽成(元)" >{{getFee(orderItem.commissionFee)}}</el-descriptions-item>
            <el-descriptions-item label="入账金额（元）" >{{getFee(orderItem.entryAmt)}}</el-descriptions-item>
            <el-descriptions-item label="附言">{{ orderItem.remarks}}</el-descriptions-item>
          </el-descriptions>

          <br/>
          <el-descriptions class="margin-top" title="分账明细"  :column="1" border>
          </el-descriptions>
          <el-table :data="orderItem.feeList" border  stripe style="margin-top:10px;">
            <el-table-column prop="merName" label="商户"/>
            <el-table-column prop="amount" label="金额" :formatter="rounding"/>
            <el-table-column prop="settleState" label="对账状态" :formatter="getSettleState"/>
            <el-table-column prop="settleDate" label="对账日期"/>
            <el-table-column prop="outPaymentStatus" label="出金状态" :formatter="getOutPaymentStatus"/>
          </el-table>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>

<script>
import XLSX from "xlsx";

export default {
  name: "index.vue",
  data() {
    return {
      fullscreenLoading: false,
      list: [],
      disabled:true,
      orderId:null,
      isVisible:false,
      merchantId:null,
      date: [],
      defaultTime:['00:00:00', '23:59:59'],
      level:localStorage.getItem("level"),
      merchant_list:[],
      orderItem:{
        txnAmt:null,
        txnAmtDiscount:null,
        txnAmtPay:null,
        status:null,
        merName:null,
        createdAt:null,
        orderNo:null,
        commissionFee:null,
        feeList:[],
        entryAmt:null,
        trxType:null,
        payType:null,
        thirdOrderNo:null,
        orderDesc:null,
        settleDate:null,
        settleState:null,
        outPaymentDate:null,
        outPaymentStatus:null,
        outPaymentTransferNo:null,
        rateFee:null,
        remarks:null
      }
    }
  },
  mounted() {
    this.addDate();
    this.getList();
    this.getMerchantList();
  },
  methods: {
    showDate(value){
      if(value===null){
        this.addDate();
      }
    },
    addDate () {
      this.date =[];
      let nowDate = new Date()
      let date = {
        year: nowDate.getFullYear(),
        month: nowDate.getMonth() + 1,
        date: nowDate.getDate()
      }
      let start = date.year + '-' + date.month + '-' + date.date + ' 00:00:00';
      let end = date.year + '-' + date.month + '-' + date.date + ' 23:59:59';
      this.date.push(start);
      this.date.push(end);
    },
    getSettleState(row,column){
      if(row[column.property]){
        return "已对账";
      } else {
        return "未对账";
      }
    },
    getOutPaymentStatus(row,column){
      if(row[column.property]){
        return "已出金";
      } else {
        return "未出金";
      }
    },
    getStatus(status){
      switch (status){
        case "SUCCESS":
          return "交易成功";
        case  "FAILURE" :
          return "交易失败";
        case "PENDING":
          return "交易审核中";
        case "REFUND":
          return "退款完成";
        case "CLOSED":
          return "交易已关闭";
      }
    },
    getPayType(item){
      if(item){
        if(item==='EP138'||item==='EP139'||item==='EP140'){
          return "微信支付"
        } else if(item==='EP159'||item==='EP160'||item==='EP161'){
          return "支付宝支付"
        } else {
          return "农行支付"
        }
      } else {
        return "农行支付"
      }
    },
    clearRateParams(done){
      this.orderItem= {
        txnAmt:null,
        txnAmtDiscount:null,
        txnAmtPay:null,
        status:null,
        merName:null,
        createdAt:null,
        orderNo:null,
        commissionFee:null,
        feeList:[],
        entryAmt:null,
        trxType:null,
        payType:null,
        thirdOrderNo:null,
        orderDesc:null,
        settleDate:null,
        settleState:null,
        outPaymentDate:null,
        outPaymentStatus:null,
        outPaymentTransferNo:null,
        rateFee:null,
        remarks:null
      };
      this.isVisible = false;
      done();
    },
    getFee(value) {
      return  (value/100).toFixed(2);
    },
    rounding(row,column) {
      return  (row[column.property]/100).toFixed(2);
    },
    getMerchantId(){
      if(this.merchantId){
        return this.merchantId
      } else {
        if(this.level==='1'){
          return  null;
        } else {
          return localStorage.getItem("merchantId");
        }
      }
    },
    getList(){
      let _this = this
      let params ={
        startDate:_this.date?_this.date[0]:null,
        endDate:_this.date?_this.date[1]:null,
        merchantId:_this.getMerchantId(),
        orderId:_this.orderId
      }
      _this.fullscreenLoading = true
      _this.disabled = true;
      adminHttp.post('/backend/order/getStatisticData',params).then(result => {
        if (result && result.code === 200) {
          _this.list = result.data;
          if(_this.list!=null&&_this.list.length>0){
            _this.disabled = false;
          } else{
            _this.disabled = true;
          }
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求错误')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },getMerchantList(){
      let _this = this
      _this.fullscreenLoading = true
      let params={
        page: 0,
        page_size: 10000
      }
      adminHttp.post('/backend/merchant/queryMerchantList',params).then(result => {
        if (result && result.code === 200) {
          if(result.data!==null&&result.data.content.length>0){
            let data = result.data.content;
            for(let i=0;i<data.length;i++){
              let obj ={
                label:data[i].merName,
                value:data[i].id
              }
              _this.merchant_list.push(obj);
            }
          }
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    },
    getLabel(item){
      return "分账明细-"+item.merName;
    },
    exportExcel() {
      if(!this.disabled){
        let list = [];
        if(this.list!=null&&this.list.length>0){
          for(let i=0;i<this.list.length;i++){
            let data = this.list[i];
            let obj = {
              "序号":i+1,
              "订单号":data.orderNo,
              "商铺名":data.merName,
              "实收金额":data.entryAmt,
              "支付日期":data.date
            }
            list.push(obj);
          }
        } else {
          this.$message.error("无交易记录");
          return;
        }

        const ws = XLSX.utils.json_to_sheet(list)
        /* 新建空workbook */
        const wb = XLSX.utils.book_new()
        /* 添加worksheet，当然你可以添加多个，这里我只添加一个 */
        XLSX.utils.book_append_sheet(wb, ws, 'record')

        const wbout = XLSX.write(wb, {
          bookType: 'xlsx',
          bookSST: true,
          type: 'array'
        })

        let url = window.URL.createObjectURL(new Blob([wbout]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', '交易流水记录' + '.xls')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) //下载完成移除元素
        window.URL.revokeObjectURL(url) //释放掉blob对象
      } else{
        this.$message.warning("尚未进行查询，无法导出数据")
      }
    },
    scanDetail(orderNo){
      let _this = this
      _this.fullscreenLoading = true
      let params={
        orderNo:orderNo
      }
      adminHttp.post('/backend/order/getOrder',params).then(result => {
        if (result && result.code === 200) {
          if(result.data!=null){
            let data = result.data
            _this.orderItem ={
              txnAmt:data.txnAmt,
              txnAmtDiscount:data.txnAmtDiscount,
              txnAmtPay:data.txnAmtPay,
              status:data.status,
              merName:data.merName,
              createdAt:data.createdAt,
              orderNo:data.orderNo,
              commissionFee:data.commissionFee,
              feeList:data.feeList,
              entryAmt:data.entryAmt,
              trxType:data.trxType,
              payType:data.payType,
              thirdOrderNo:data.thirdOrderNo,
              orderDesc:data.orderDesc,
              settleDate:data.settleDate,
              settleState:data.settleState,
              outPaymentDate:data.outPaymentDate,
              outPaymentStatus:data.outPaymentStatus,
              outPaymentTransferNo:data.outPaymentTransferNo,
              rateFee:data.rateFee
            }
          }
          _this.isVisible  = true;
        } else {
          _this.$message.error(result.message)
        }
      }).catch(error => {
        _this.$message.error('请求失败')
      }).finally(() => {
        _this.fullscreenLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>